import React from 'react'
import Layout from 'components/layout/layout'
import Helment from 'react-helmet'
import LogoB from 'images/svgs/bantu-logo-b.svg'
import BusinessNameColumn from 'images/A_Z_order_Bantu_market.png'
import CategoryColumn from 'images/Business_type_categories_Bantu_Market.png'
import CityMenu from 'images/Bantu_Market_city_toggle.png'
import 'styles/pages/bantu-directory.scss'

const BantuDirectoryPage = () => (
  <Layout isCentered>
    <Helment>
      <title>Bantu Directory</title>
    </Helment>
    <section className="padding-6 opaque-container br-small">
      <header className="page-header">
        <a
          href="https://docs.google.com/spreadsheets/d/1HwD6jFHwOA-kBJHvJt7cdfq-TiH9ZQzcpCyRgugmC2A/edit?pli=1#gid=821814619"
          target="_blank"
          rel="noopener noreferrer"
          className="link"
        >
          <h2 className="margin-y-4 text-center">Bantu Directory</h2>
        </a>
      </header>

      <p className="margin-y-4 container-75">
        Our goal is to connect black businesses with consumers. Here you can
        search, find and connect with black owned businesses in cities across
        the United States and even a few globally. Whether you’re looking for a
        hair salon in your area or traveling and want to find a black-owned
        coffee shop in the city- Bantu’s aim is to be the place to find it!
      </p>

      <h3 className="margin-y-4">Suggested usage</h3>

      <p className="margin-y-4 container-75">
        Each page or sheet is sectioned by featured cities and surrounding
        areas. You can find the city you’re looking for by toggling between the
        tabs at the bottom of the screen or between all sheets, using the tab at
        the bottom left next to the ‘+’ sign represented by 3 horizontal lines.
      </p>

      <div className="img-container">
        <a href={CityMenu} target="_blank" rel="noopener noreferrer">
          <figure>
            <img className="container-75" src={CityMenu} alt="" />
            <figcaption>
              The tab to show all cities is circled in the bottom left. Each of
              the other city tabs can be seen next to the toggle labeled with
              the city name.
            </figcaption>
          </figure>
        </a>
      </div>

      <p className="margin-y-4 container-75">
        If you’re looking to browse businesses, you can find the categories
        sections under the ‘Business type’ column.
      </p>

      <div className="img-container">
        <a href={CategoryColumn} target="_blank" rel="noopener noreferrer">
          <figure>
            <img className="container-75" src={CategoryColumn} alt="" />
            <figcaption>
              The &quot;Business Type&quot; column is circled in red with arrows
              pointing to example categories such as &quot;Food&quot; and
              &quot;Branding & Marketing&quot;
            </figcaption>
          </figure>
        </a>
      </div>

      <p className="container-75 margin-y-4">
        Or, if you’re seeking a specific business in an area by name you can
        search the business name in column A (As shown below).
      </p>

      <div className="img-container">
        <a href={BusinessNameColumn} target="_blank" rel="noopener noreferrer">
          <figure>
            <img className="container-75" src={BusinessNameColumn} alt="" />
            <figcaption>
              The &quot;Business Name&quot; column is circled in red with an
              arrow pointing to it.
            </figcaption>
          </figure>
        </a>
      </div>

      <p className="container-75">And there you have it!</p>
      <h4 className="margin-y-6">
        <a
          href="https://docs.google.com/spreadsheets/d/1HwD6jFHwOA-kBJHvJt7cdfq-TiH9ZQzcpCyRgugmC2A/edit?pli=1#gid=821814619"
          target="_blank"
          rel="noopener noreferrer"
          className="link"
        >
          Follow this link to access the Bantu Directory
        </a>
      </h4>

      <h3 className="margin-y-4">
        Don’t see your business or favorite black-owned shop?
      </h3>

      <p className="container-75 margin-y-4">
        Don’t fret, you can{' '}
        <a
          className="link text-bold"
          href="https://mailchi.mp/bba6d837013e/bantubizz"
          target="_blank"
          rel="noopener noreferrer"
        >
          submit the business here
        </a>{' '}
        and our team will update the system to feature it.
      </p>
      <p className="container-75 margin-y-4">
        If you’d like to provide feedback, update your business information or
        ask a general question--
        <a href="mailto:contactus@bantu.market" className="link text-bold">
          send us an email
        </a>
        .
      </p>
      <p className="container-75 margin-y-6">
        Also, if you’d like to stay-up-to-date with all things Bantu, follow us
        on{' '}
        <a
          className="link text-bold"
          href="https://www.instagram.com/bantu.market/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Instagram
        </a>
        ,{' '}
        <a
          className="link text-bold"
          href="https://twitter.com/TheBantuMarket"
          target="_blank"
          rel="noopener noreferrer"
        >
          Twitter/X
        </a>{' '}
        or{' '}
        <a
          className="link text-bold"
          href="https://www.facebook.com/TheBantuMarket"
          target="_blank"
          rel="noopener noreferrer"
        >
          Facebook
        </a>
        .
      </p>

      <p>#BuyBlackwithBantu</p>
      <p className="margin-y-2">Your source for everything black-owned,</p>
      <LogoB className="about-logo-b" />
    </section>
  </Layout>
)

export default BantuDirectoryPage
